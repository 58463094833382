.my-project {
  background-color: #f2f6f9;
  min-height: 100vh;
}
.my-project-header {
  display: flex;
  align-items: center;
  justify-content: center;
}
.content {
  margin: 60px 0;
}

.information,
.team-member,
.detail-description,
.description-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: #fff;
  margin-top: 50px;
  border-radius: 3px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 40px;
}
.title-information {
  font-size: 25px;
  margin-bottom: 10px;
}
.more-link {
  display: flex;
  align-items: center;
  text-decoration: underline;
}
.picture-frames-pool{
  border-radius: 10px;
  height: 200px;
  background-color: rgb(240, 240, 240);
  margin-bottom: 60px;
}
.picture-frames {
  border-radius: 10px;
  height: 300px;
  background-color: rgb(240, 240, 240);
  margin-bottom: 60px;
}
.picture-frames-investor{
 border-radius: 10px;
  height: 180px;
  background-color: rgb(240, 240, 240);
}
.ant-upload-picture-card-wrapper {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}
.ant-upload.ant-upload-select-picture-card {
  width: 100%;
  height: 100%;
}
.ant-upload.ant-upload-select-picture-card img {
  max-width: 100%;
  max-height: 100%;
}
.img-note {
  width: 100%;
}
.grayscale {
  filter: grayscale(100%);
}
.grayscale img {
  width: 80%;
}
.team-member-content {
  height: 66vh;
  width: 100%;
}
.member-required {
  margin-bottom: 10px;
}
.form-close {
  cursor: pointer;
  font-weight: bolder;
  font-size: 14px;
  padding: 0 15px;
}
.btn-add-member {
  margin-bottom: 20px;
}
.form-first {
  margin-bottom: 60px;
}
.content-fist {
  height: 35vh;
}
.description-container {
  padding: 20px 40px;
}
.content-description {
  padding-top: 20px;
}
.form-description {
  padding-top: 50px;
}
.content-btn {
  margin-bottom: 20vh;
  padding: 0 200px;
}

.description-item {
  height: 28vh;
}
.btn-create-project {
  margin: 30px 0;
}
.btn-create-project .ant-btn {
  height: 50px;
  padding: 0 50px;
  font-size: 18px;
}
.my-project-item {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 8px 15px;
  width: 340px;
  margin-bottom: 20px;
}
.my-project-title h3 {
  font-weight: 700;
  font-size: 18px;
  padding-top: 15px;
}
.my-project-img {
  height: 164px;
  border-radius: 5px;
  background-color: #ddd;
  overflow: hidden;
}

.project-img img {
  height: 100%;
  width: 100%;
}
.my-project-status p {
  text-align: center;
  padding-left: 5px;
}
.my-project-status p span {
  padding-left: 5px;
  font-size: 15px;
}
/* ::-webkit-scrollbar {
  display: none;
} */
