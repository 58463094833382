.rt-timeline {
  position: relative;
  overflow: hidden;
}

.rt-timeline__header {
  border-bottom: 1px solid #757575;
}

.rt-timeline__header-scroll {
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.rt-timeline__header.rt-is-sticky {
  position: fixed;
  top: 0;
  z-index: 1;
  overflow: hidden;
}

.rt-timeline__body {
  position: relative;
  // background: white;
  background: transparent;
  padding-top: 40px;
  border-left: 1px solid #757575;
  border-right: 1px solid #757575;
}
