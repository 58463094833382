.rt-timebar-key {
  height: $react-timelines-header-row-height + $react-timelines-border-width;
  margin-right: 80px;
  line-height: $react-timelines-header-row-height;
  text-align: left;
  font-size: 20px;
  border-bottom: 1px solid #757575;
  position: relative;
  color: white;

  &:after {
    position: absolute;
    content: '';
    background-color: #384bf2;
    width: 45px;
    height: 3px;
    bottom: -2px;
    left: 0px;
    z-index: 1;
  }
}