.tab-content .tab-pane {
  width: 100% !important;
  max-width: 100% !important;
  height: auto !important;
}

@media (max-width: 767.98px) {
  .img-intro {
    overflow: hidden;
  }

  #pool-home .title-home h2:before {
    left: 42%;
  }

  #about-us h3 {
    font-size: 26px;
  }

  #about-us article p {
    min-height: 100px;
  }

  .order-bottom {
    order: 1;
  }

  #features article:last-child ul {
    padding-left: 0 !important;
  }

  #features article ul li img {
    display: block;
    margin-bottom: 10px;
  }

  #features article ul li span {
    font-size: 22px;
  }

  #dao-funding .carousel-indicators {
    right: 0;
    bottom: -65px;
  }

  #partner {
    padding:40px 0;
  }

  .title_main h2 {
    margin-bottom: 40px;
  }

  #listProject,
  #poolListItem {
    padding-right: 0 !important;
  }

  .item-box .nav-tabs {
    margin-top: 20px;
  }

  .banner-vc {
    background-size: cover;
  }

  .banner-vc .btn-join {
    border: 1px solid #fff;
    color: #fff !important;
    width: 78%;
    margin: 0 40px !important;
  }

  .title_main {
    margin-top: 20px;
  }

  .clear-filter .text,
  .title-select {
    font-size: 14px;
  }

  .filter-select {
    margin-right: 0;
    margin-bottom: 30px;
  }

  .clear-filter {
    padding: 13px 0;
  }

  #nav-tab {
    width: 100%;
    margin-top: 40px;
  }

  .search-config {
    margin-top: 20px;
  }

  .title_main h2 {
    font-size: 30px;
  }

  .content-width .content-width {
    padding-left: 0;
    padding-right: 0;
  }

  .content-box {
    margin-top: 35px;
  }

  .metric li {
    float: none;
    width: 100%;
  }

  .list-group-item {
    padding: 8px 0;
  }

  .scrollspy-main h4 {
    font-size: 20px;
  }

  .vcs-list {
    padding-left: 0;
    padding-right: 0;
  }

  .item-card .img-vcs {
    height: auto !important;
  }

  main {
    padding-top: 100px;
    overflow-x:hidden;
  }

  .list-vc li span {
    display: block;
  }

  .bg-show {
    overflow: hidden;
  }

  .list-vc li {
    font-size: 14px;
    padding: 20px 0;
  }

  .similar-buidls {
    overflow: hidden;
  }

  .group-svg svg:first-child {
    margin-right: 10px;
  }

  .bg-team-member {
    padding: 20px;
  }

  .item-member {
    width: 100%;
  }

  .ant-spin-container > div {
    margin-right: 0 !important;
  }

  .table-team-member .table > :not(caption) > * > * {
    padding: 20px 15px;
  }

  .item-card .link-a a {
    background: #384bf2;
    border: 1px solid #384bf2 !important;
    border-radius: 30px;
  }

  .item-card .link-a {
    opacity: 1;
  }

  .item-card {
    margin-bottom: 30px;
    background: #29292f;
  }

  .bg-show .btn-join .btn-submit {
    float: none;
    width: 140px;
  }

  .list-vc {
    line-height: 2.5;
  }
}

@media (max-width: 320.98px) {
  .text_a a {
    margin-right: 5px;
  }

  #introduction .text_a a {
    width: 117px;
  }

  .title-home .text_a a {
    display: block;
    margin-bottom: 10px;
  }
}

/* ================== BEGIN: recipeCarousel (Partner) ================== */
@media (max-width: 767px) {
  #recipeCarousel .carousel-inner .carousel-item > div {
    display: none;
  }
  #recipeCarousel .carousel-inner .carousel-item > div:first-child {
    display: block;
  }

  #partner .title-home h2:before {
    left: 43%;
  }

  .milestone .rt-layout {
    margin-left: 0 !important;
  }

  .rt-layout__side {
    width: 185px !important;
  }

  .rt-layout__main {
    width: calc(100% - 185px) !important;
  }

  .rt-timeline {
    width: 100% !important;
  }
}

#recipeCarousel .carousel-inner .carousel-item.active,
#recipeCarousel .carousel-inner .carousel-item-next,
#recipeCarousel .carousel-inner .carousel-item-prev {
  display: flex;
}

/* medium and up screens */
@media (min-width: 768px) {
  #recipeCarousel .carousel-inner .carousel-item-end.active,
  #recipeCarousel .carousel-inner .carousel-item-next {
    transform: translateX(25%);
  }

  #recipeCarousel .carousel-inner .carousel-item-start.active,
  #recipeCarousel .carousel-inner .carousel-item-prev {
    transform: translateX(-25%);
  }
}

#recipeCarousel .carousel-inner .carousel-item-end,
#recipeCarousel .carousel-inner .carousel-item-start {
  transform: translateX(0);
}
/* ================== END: recipeCarousel (Partner) ================== */

/* ======== Update 2022/09/29 17:00 ======== */
.box-detail-summary {
  background: linear-gradient(180deg, #23242b 1%, #1a1b1e);
  padding: 25px 25px 25px 50px;
}

.box-detail-summary ul {
  padding-left: 0;
}

.social li {
  float: left;
  margin-right: 100px;
}

.social li a {
  color: #868c9f;
  display: block;
}

.social li img {
  margin-top: -3px;
  margin-right: 5px;
}

.box-img {
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  max-width: 100%;
  height: 206px;
  background: rgba(255, 255, 255, 0.05);
}

.box-img img {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 10px;
  transform: translate(-50%, -50%);
}

.box-detail-summary {
  margin-top: 130px;
  margin-bottom: 60px;
  border-radius: 15px;
}

.box-detail-summary aside {
  margin-top: -90px;
}

.box-detail-summary aside h5 {
  margin: 20px 0 30px;
  font-size: 25px;
}

.box-detail-summary aside ul li {
  margin-bottom: 30px;
}

.box-detail-summary aside ul li img {
  margin-right: 15px;
  position: relative;
  top: -2px;
}

.box-detail-summary aside ul li span {
  border-bottom: 1px solid #444970;
  padding-bottom: 20px;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
}

.box-detail-summary aside ul li:last-child span {
  border-bottom: 0;
  padding-bottom: 0;
}

.box-detail-summary aside ul li b {
  color: #8894ff;
}

.box-detail-summary .title_main {
  margin-top: 40px;
}

.box-detail-summary .title_main h2 {
  font-size: 20px;
}

.box-trans {
  background: rgba(0, 0, 0, 0.3);
  padding: 20px;
  border-radius: 10px;
  min-height: 230px;
}

.box-trans h4 {
  margin-bottom: 10px;
}

.box-trans p {
  color: #fff;
  line-height: 1.8;
  margin-bottom: 0;
}

.box-trans ul li {
  float: left;
  width: 47%;
  border: 1px solid #5e77ff;
  border-radius: 10px;
  background: #0e1344;
  padding: 10px;
  text-align: center;
  font-size: 13px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.box-trans ul li b {
  display: block;
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  overflow: hidden;
}

.content-detail-summary .nav-tabs {
  margin-top: -100px;
}

.content-detail-summary .nav-tabs .nav-item.show .nav-link,
.content-detail-summary .nav-tabs .nav-link.active {
  color: #8894ff;
  border-bottom: 2px solid #8894ff;
}

.content-detail-summary .tab-content {
  padding-top: 52px;
}

.investment_de ul li b {
  border: 1px solid rgba(255, 255, 255, 0.4);
  padding: 4px 15px;
  border-radius: 30px;
  font-size: 12px;
  color: #fff;
  margin-right: 7px;
}

.investment_de ul li {
  margin-bottom: 20px;
}

.investment_de ul li small {
  margin-right: 10px;
  font-size: 14px;
  min-width: 50px;
  display: inline-block;
}

.dibi {
  padding-left: 80px;
}

#list-build .avatar .overlay {
  padding: 10px;
}

#list-build .item-box .nav-tabs .nav-link {
  padding: 10px 20px;
}

#list-build .tab-content ul li {
  float: left;
  width: 30%;
  margin-bottom: 0;
  overflow:hidden;
  margin-right:30px;
}

#list-build .tab-content ul li span {
  color: #a8b1ff;
  font-size: 20px;
}

#list-build .avatar {
  overflow: hidden;
  position: relative;
  height: 185px;
  border-radius: 15px;
}

#list-build .avatar img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#list-build .overlay img {
  transform: none;
  position: static;
}

#list-build .item-box {
  padding: 15px 15px 0 15px;
}

#list-build .item-box h4 {
  font-size: 22px;
}

#list-build .item-box .link-a {
  margin-top: 140px;
}

#list-build .item-box .link-a a {
  background: #384bf2;
  border: 0;
  font-size: 13px;
}

#list-build .item-box .tab-content {
  min-height: 160px;
}

#list-build .item-box .tab-content ul {
  margin-top: 40px;
}

.title_main{
  margin-left:0;
  margin-right:0;
}

.box-left-desc{
  margin-bottom:10px;
}

.box-left-desc h4{
  float:left;
  margin-right:10px;
}

.box-left-desc .group_tags{
  padding-top:3px;
}

.medit-logo-focus img{
  border:1px solid #323232;
  padding:30px;
  background-color: #202020;
  margin-bottoM:20px;
}

.box-medit-logo{
  margin-top:40px;
}

.icon-logo-medit{
  margin:0 0 30px;
  background-color: #202020;
  border:1px solid #323232;
  padding:30px;
}

.icon-logo-medit img{
  display: block;
  max-width: 20%;
  margin:0 auto;
}

.box-bg .avatar{
  position:relative;
  overflow:hidden;
}

.box-bg .avatar img{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.menu-footer-all{
  padding-left:0;
}

.menu-footer-all li a{
  color:#cecece;
  display: block;
  padding:5px 0 10px;
}

.menu-footer-all li a:hover{
  color:#384bf2;
}

.footer_bg{
  background-color: #101010;
}

@media (min-width:768px){
  .menu-footer-all li{
    float:left;
    width:25%;
  }
}

@media (max-width:767.98px){
	.text_a{
		display:none;
	}
	
	#introduction article h1{
		margin-top:0 !important;
	}
	
	#introduction p{
		margin-top:10px !important;
	}
	
	#introduction .fanpages{
		margin-top:0 !important;
	}
	
	.img-intro{
		margin-top:20px !important;
	}
	
	.title-home h2{
		font-size:28px !important;
	}
	
	.title-home h2:before{
		bottom: -16px !important;
	}
	
	.title-home P{
		font-size:15px !important;
	}
	
	.public-pools{
		overflow:hidden;
	}
	
	.public-pools img{
		margin-left: -40% !important;
		max-width: 180% !important;
	}
	
	#about-us{
		padding:0 !important;
	}
	
	.rel-img{
		height:300px;
	}
	
	.rel-img img{
		position:absolute !important;
		top:-35% !important;
	}
	
	#about-us article .box-invest p{
		min-height:auto !important;
	}
	
	#about-us .focus{
		margin-bottom:0 !important;
	}
	
	#dao-funding .carousel-item h3{
		font-size:28px !important;
	}
	
	#dao-funding .carousel-item p{
		font-size:15px !important;
	}
	
	#interested .title-home h2{
		font-size:38px !important;
	}
	
	#interested article p{
		font-size:16px !important;
	}

  .menu-footer-all li{
    width:50%;
    float:left;
  }

  .pool li:last-child{ width:100%; }
}