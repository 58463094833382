.logo {
  height: 32px;
  margin: 16px;
  text-align: center;
}
.text-step{
  color: #7C7E94;
}
.red-color {
  color: red;
}

.blue-color {
  color: #384bf2;
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}

.main-body {
  height: 100vh;
  background: black;
  overflow-y: auto;
}

.ant-modal-mask {
  z-index: 9997;
}

.ant-modal-wrap {
  z-index: 9998;
}

.ant-modal-content {
  border-radius: 1em;
}

.explore-head {
  /* background-color: #202636; */
  background-color: white;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px 0px 40px;
  border-bottom: 1px solid rgb(245, 242, 242);
}

.wallet-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.wallet-item {
  width: 120px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wallet-item:hover {
  background-color: #ddd;
  cursor: pointer;
}

.wallet-item img {
  height: 32px;
}

.wallet-notify {
  height: 30px;
}

.wallet-box {
  color: black;
}

.explore-box {
  background-color: #f2f6f9;
  display: block;
}

.list-explore {
  -ms-overflow-style: none;
  scrollbar-width: none;
  /* overflow-y: scroll; */
}

/* .list-explore::-webkit-scrollbar {
  display: none;
} */
.project-status {
  display: block;
  height: 100%;
}

.project-status ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  width: 180px;
  justify-content: space-between;
  height: 100%;
}

.project-status ul li {
  color: #676b72;
  font-weight: 600;
  display: flex;
  align-items: center;
  border-bottom: 2px solid transparent;
  font-size: 13px;
}

.project-status ul li.active {
  color: rgb(7, 160, 231);
  border-bottom: 2px solid rgb(7, 160, 231);
}

.project-status ul li:hover {
  cursor: pointer;
  color: rgb(10, 173, 248);
  border-bottom: 2px solid rgb(7, 160, 231);
}

.search-explore {
  width: 350px;
  margin: 30px auto;
}

.project-item {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 8px;
  width: 265px;
}

.project-img {
  height: 164px;
  border-radius: 5px;
  background-color: #ddd;
  overflow: hidden;
}

.project-img img {
  height: 100%;
}

.project-social {
  padding: 20px 0px 10px 0px;
}

.project-more {
  padding-top: 20px;
}

.project-more a {
  border: 1px solid #0099d5;
  border-radius: 3px;
  color: #0099d5;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

.project-more a:hover {
  background-color: #0099d5;
  color: white;
}

.project-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.project-item {
  margin-bottom: 20px;
}

/* #myProjectListItem {
  height: calc(100vh - 130px - 49px - 40px);
}

#listPool {
  height: calc(100vh - 130px - 49px - 40px);
  padding-right: 10px;
} */

.scroll-infinity {
  overflow-y: auto;
  overflow-x: hidden;
}

.scroll-infinity::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.scroll-infinity::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
}

.scroll-infinity::-webkit-scrollbar-thumb {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, rgb(122, 153, 217)),
    color-stop(0.72, rgb(73, 125, 189)),
    color-stop(0.86, rgb(28, 58, 148))
  );
}

.loading-box {
  display: flex;
  height: 80px;
}

.add-sale {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.d-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-items-center {
  align-items: center;
}

.text-center {
  text-align: center;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: end;
}

.justify-content-space-between {
  justify-content: space-between;
}

.cursor-pointer {
  cursor: pointer;
}
.text {
  font-size: 14px;
  color: #676b72;
}

.pl-4 {
  padding-left: 10px;
}

.red {
  color: red;
}

.green {
  color: #05ff00;
}

.delete-btn {
  color: rgb(221, 87, 87) !important;
}

.delete-btn:hover {
  color: red !important;
}

.violent {
  padding-left: 5px;
  color: #a8b1ff;
}

.vc-color {
  color: #b3b3b3;
}

.connectwallet-btn {
  display: flex;
  height: 30px;
  justify-content: center;
  align-items: center;
  background-color: #0099d5;
  padding: 0px 20px;
  color: white;
  border-radius: 3px;
}

.connectwallet-btn:hover {
  cursor: pointer;
  background-color: #027dad;
}

.create-pool {
  display: block;
  background-color: white;
}

.page-title {
  display: flex;
  height: 50px;
  font-size: 28px;
  color: #0072ff;
  justify-content: center;
  align-items: center;
}

.createpool-footer {
  display: flex;
  justify-content: flex-end;
  background: white;
}

.milestone-form {
  background-color: #1f2026;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.milestone-title {
  height: 40px;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  color: #6a9ae4;
  padding-left: 20px;
  font-size: 24px;
}

.milestone-body {
  padding: 20px;
}

.addnew-item-table {
  display: flex;
  height: 70px;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  background-color: #f2f6f9;
}

.addnew-item-table:hover {
  cursor: pointer;
  border-color: rgb(46, 194, 46);
}

.progress-ctl {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 25px;
}

.progress-ctl .progress-title {
  z-index: 1;
}

.pr-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.year-box {
  position: relative;
}

.year-header {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: row;
  height: 40px;
  width: 100%;
  margin-left: 200px;
}

.year-item {
  color: white;
  font-size: 22px;
  background-color: crimson;
  border: 1px solid white;
  flex: 1;
  -webkit-flex: 1;
  text-align: center;
}

.month-header {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: row;
  height: 40px;
  width: 100%;
  margin-left: 200px;
}

.month-item {
  color: white;
  max-width: 60px;
  font-size: 22px;
  background-color: gold;
  border: 1px solid white;
  flex: 1;
  -webkit-flex: 1;
  text-align: center;
}

.milestone-item {
  display: flex;
}

.milestone-menu {
  max-width: 200px;
  min-width: 200px;
  border-right: 1px solid #ddd;
}

.ant-progress-text {
  display: none;
}

.ant-statistic-content {
  color: red;
}

.item-box {
  color: rgb(187, 179, 179);
}

.title-step li {
  width: 16%;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 50px;
  color: #ffff;
  background-color: #292c38;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 5px;
}

.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
  .ant-select-selector {
  min-height: 50px;
  border-radius: 5px;
}

.ant-input {
  height: 50px;
  color: #ffff;
  background-color: #292c38;
  border: none;
  border-radius: 5px;
}

.ant-input-number-input {
  height: 50px;
  color: #ffff;
  background-color: #292c38;
  border: none;
  border-radius: 5px;
}

.ant-input-number {
  border: none;
  border: 5px;
  background-color: none;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
  background-color: #292c38;
}

.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: #292c38;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background-color: #292c38;
}

.ant-form-item-label > label {
  color: #ffff;
}

.ant-table
  .ant-table-container
  .ant-table-content
  table
  thead.ant-table-thead
  .portfolio-header
  .ant-table-cell {
  background-color: #384bf2;
}

.ant-upload.ant-upload-select-picture-card {
  background-color: #bcc3cf;
  border-radius: 10px;
}

.w-md-editor-text {
  background-color: rgba(41, 44, 56, 0.75);
}

.w-md-editor {
  box-shadow: none;
}

.w-md-editor-text {
  color: #fff;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #292c38;
  border: none;
  padding: 5px;
  border-radius: 5px;
}

.ant-select-multiple .ant-select-selection-item {
  align-items: center;
  height: 35px;
  border-radius: 5px;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  min-height: 50px;
  border-radius: 5px;
}

.ant-input-affix-wrapper {
  border: none;
  background-color: #292c38;
}

.ant-picker {
  background-color: #292c38;
  width: 100%;
  border: none;
  padding: 15px 15px;
}

.pool-box-upload .bg-file {
  background: #2f323c;
  padding: 20px 20px 0px 20px;
  border-radius: 10px;
  margin: 0;
}

.pool-box-upload .file-upload {
  /* background-color: #bcc3cf; */
  /* padding: 15px; */
  border-radius: 10px;
}

.pool-box-upload .file-upload img {
  margin: 10px auto;
  display: block;
  max-width: 100%;
}

.pool-box-upload .ant-upload-drag-container {
  height: 90px;
  display: block;
  overflow: hidden;
}

.pool-box-upload .ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 0;
}

.pool-box-upload .ant-upload.ant-upload-drag p.ant-upload-text {
  font-size: 13px;
}

.ant-btn-default {
  height: 3em;
  min-width: 6em;
  border-radius: 2em;
  background-color: #384bf2;
}

.ant-btn-default:focus {
  color: #ffff;
  background-color: #384bf2;
}

.ant-btn-default:hover {
  background-color: #4658ff;
  color: #ffff;
}

.required {
  font-size: 15px;
}

.required:before {
  padding: 5px;
  content: ' *';
  color: red;
}

.ant-input-group-addon
  .ant-select.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: #2f323c;
}

.claim-btn {
  margin: 0px 0px 0px 10px !important;
  height: 30px;
}

.over-flow {
  white-space: nowrap;
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hr-vc {
  background-color: #343435;
  height: 1px;
}

.content-img {
  justify-content: center;
  align-items: center;
  display: flex;
}

.avatar {
  width: 100%;
  height: 100%;
  background: rgba(63, 63, 63, 0.267);
  border-radius: 25px;
  overflow: hidden;
}

.item-card .img-fluid {
  width: 100%;
  height: 20vh;
}

.item-card .img-vcs {
  width: 100%;
  height: 10vh;
}

.container {
  position: absolute;
  top: 10%;
  left: 23%;
  width: 50%;
}

.text {
  display: inline;
}

.read-or-hide {
  color: #868c9f;
  cursor: pointer;
}

.cke_reset {
  color: #000;
}

.milestone-error {
  border: 1px solid red;
}

.search-config {
  display: flex;
  align-items: center;

  position: relative;
}

.total-search {
  right: 10px;
  position: absolute;
}

.deposit_review {
  width: 500px !important;
}

.box-main {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 15px;
}

.rt-track-key__entry:hover {
  cursor: pointer;
}

.j-right {
  justify-content: flex-end;
}

.step-pool-wrapper {
  display: flex;
}

.step-pool-wrapper .title-show {
  width: 200px;
  padding-right: 20px;
}

.step-pool-wrapper .title-step li {
  width: 20%;
}

.accordion {
  /* background-color: #000; */
  border-radius: 10px;
}

.ant-collapse-borderless {
  background-color: transparent;
}

.ant-collapse > .ant-collapse-item {
  margin-bottom: 30px;
  border-bottom: none;
  background-color: #1e1e23;
  font-weight: 600;
  border-radius: 10px;
}

.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 10px;
}

.ant-list-items {
  display: flex;
  flex-wrap: wrap;
}

.investor-col {
  margin-top: 20px !important;
}

.investor-col > .ant-collapse-item {
  margin-bottom: 0px;
  border-bottom: none;
  background-color: transparent;
  font-weight: normal;
  border-radius: 0;
}

.investor-col .ant-collapse-header-text {
  font-size: 22px;
}
/* .ant-collapse-icon-position-end>.ant-collapse-item>.ant-collapse-header{
  padding: 12px 40px 12px 0px;
  border-bottom: 1px solid #ddd;
} */
.investor-col .ant-collapse-header {
  position: relative;
  padding: 12px 40px 12px 0px !important;
  border-bottom: 1px solid #919191;
}
.investor-col > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0px;
}
.investor-col .ant-collapse-content-box {
  padding: 30px 0px 0px 0px !important;
}
.pleft10 {
  padding-left: 10px;
}
.pright10 {
  padding-right: 10px;
}

.hover-icon:hover {
  color: #0072ff;
  
}
.ant-drawer-body{
  padding: 10px 0px 0px 0px;
}

.choose_file {
  position: relative;
  display: inline-block;
  border-radius: 10px;
  padding: 15px 30px;
  margin-top: 2px;
  font-size: 17px;
  cursor: pointer;
  border: 1px dashed #434343;
  border-radius: 10px;
  background-color: rgb(240, 240, 240);
}
.choose_file input[type='file'] {
  width:100%;
  height: 100%;
  cursor: pointer;
  -webkit-appearance: none;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #424850;
  opacity: 0;
}

.img-icon-similar{
  width: 30px;
}
.img-icon-similar-2{
  width: 20px;
}
.text-decoration{
  text-decoration: none;
}