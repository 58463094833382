/* ================= MEDIA SCREEN ================= */
/* ================= Class General Frame ================= */
@media (min-width: 1400px){
	.content-width {
		width: 1400px !important;
		margin: 0 auto;
	}
	
	.item-box .nav-tabs .nav-link{
		padding: 4px 7px !important;
		font-size: 13px;
	}
	
	.item-box .tab-content ul{
		margin:10px 0;
	}
	
	.item-box .tab-content ul li span{
		font-size:18px;
	}
	
	.item-box .tab-content{
		min-height:185px;
		padding-bottom:0;
	}
	
	.title-box{
		margin-top:50px;
	}
	
	.list-large{
		padding-left:40px;
	}
	
	.item-box .exceprt{
		height:190px !important;
		overflow:auto;
	}

	.img-intro .abs{
		right:67px;
	}

	.abs-2{
		right:10px !important;
	}

	.public-builds img, .public-pools img{
		margin:0 auto;
		display: block;
		width:100%;
	}
}

@media (min-width: 1150px){
	.content-width {
		width: 1150px;
		margin: 0 auto;
	}

	#partner h2{
		width:30%;
	}

	#slide-top h1{
		width:50%;
		margin:0 auto;
	}
}

/* ================= Large + Medium Devices (Laptop, PC, Tablet) ================= */
@media (min-width: 992px){
	.dropdown:hover .dropdown-menu {
        display: block;
        margin-top: 0;
        right:0;
        top: 100%;
    }

	#productSub, #exploreSub{
		right:auto;
		left:0;
	}

	.ms-auto{
		margin-right:auto;
	}

	.logo-mobile {
		display: none;
	}
	
	#navbar_main .content-width{
		display:flex;
	}
	
	.item-box .exceprt{
		height:140px;
	}

	.pdl-0{
		padding-left:0;
		padding-right:10px;
	}

	.vc-action .btn-detail, .vc-action .btn-view{
		width:170px;
	}

	.item-card .img-vcs{
		height:12vh !important;
	}

	.vc-color{
		font-size:12px;
	}

	.over-flow span{
		font-size:13px !important;
		padding-left:0 !important;
		font-weight: normal;
	}

	.vc-action{
		margin-top:2px;
	}

	.ul-nopdf li:last-child{
		padding:0 !important;
	}

	.clear-filter{
		margin-top:-50px;
	}

	.nogutter, .nogutter .row{
		--bs-gutter-x: 0 !important;
	}

	#listProject{
		padding-right:0;
	}

	.ul-nopdf .px-3{
		padding-left:0 !important;
		padding-right:2rem;
	}

	.remove_01{
		--bs-gutter-x:0 !important;
	}
}

/* ================= Medium + Small Devices (Tablet, Phone) ================= */
@media (max-width: 991.98px){
	.navbar-nav .dropdown-menu{
		position: static !important;
		transform: none !important;
		background-color: #2C2D33;
	}

	.offcanvas-header{
		padding:0;
	}
	
	.offcanvas-header .btn-close{
		position:absolute;
		top:25px;
		right:20px;
	}
	
	.mobile-offcanvas .navbar-brand{
		display:block;
		margin:10px 0;
		padding:0 0 0 20px;
	}
	
	.navbar-brand{
		padding-left:20px;
	}
	
	.header-mobile{
		padding:8px 15px;
	}
	
	.logo-mobile img {
		max-width: 132px;
		display: block;
	}
	
	#btn-menu {
		position: absolute;
		top: 18px;
		right: 20px;
		background: 0 0;
		border: 0;
	}
	
	#btn-menu span {
		width: 27px;
		height: 2px;
		display: block;
		margin-bottom: 7px;
		background: #fff;
	}
	
	#btn-menu span:nth-child(2) {
		margin-top: 9px;
		background-color:#384BF2;
	}
	
	#btn-menu span:first-child,	#btn-menu span:last-child {
		width: 13.5px;
	}
	
	#btn-menu span:first-child {
		float: right;
	}
	
	.mobile-offcanvas .container, .mobile-offcanvas .container-fluid, .offcanvas-header {
		display: block;
	}
	
	.mobile-offcanvas {
		visibility: hidden;
		transform: translateX(-100%);
		border-radius: 0;
		display: block;
		background:#2D2E37;
		position:fixed;
		top: 0;
		left: 0;
		height: 100%;
		z-index: 12;
		width: 75%;
		overflow-y: scroll;
		overflow-x: hidden;
		transition: visibility 0.3s ease-in-out, transform 0.3s ease-in-out;
	}
	
	.mobile-offcanvas.show {
		visibility: visible;
		transform: translateX(0);
	}

	#navbar_main .navbar-nav{
		margin-top:30px;
	}
	
	#navbar_main .navbar-nav .active .nav-link{
		border-bottom:0;
	}
	
	#navbar_main .navbar-nav .nav-item .nav-link{
		padding:12px 0 !important;
		position:relative;
		font-size:16px;		
		border-top:1px solid rgba(255, 255, 255, 0.15);
	}
	
	#navbar_main .navbar-nav .nav-item .nav-link:after, #navbar_main .navbar-nav .nav-item .dropdown-toggle:before{
		background: url('../images/arrow.svg') no-repeat;
		width: 13px;
		height: 14px;
		position: absolute;
		top: 17px;
		right: 0;
		content: '';
	}
	
	.sign{
		padding:10px 20px 10px;
		padding-left:0;
		text-align:center;
	}
	
	.sign .nav-item .nav-link:after{
		display:none;
	}
	
	.sign .nav-item:nth-child(2) .nav-link{
		border-top:0 !important;
	}
}

/* ================= Laptop Devices (Only Phone) ================= */
@media (min-width: 768px) and (max-width:1399.98px){
	.px-4{
		padding-right: 0.5rem !important;
    	padding-left: 0.5rem !important;
	}

	.rel-img .img-fluid{
		margin-left:0 !important;
	}

	.abs-bg{
		right: -17px !important;
	}
}

@media (min-width: 768px){
	#onlyMobile{display: none;}

	.item-member .item-avatar{height:207px;}
}

/* ================= Small Devices (Only Phone) ================= */
@media (max-width: 767.98px) {
	#slide-top h1{
		font-family: var(--font_spec);
		font-weight:500;
		background:none;
		background-clip:border-box;
		-webkit-text-fill-color:var(--white);
	}

	#slide-top h2{
		color:var(--text);
	}

	#dao-feature-02 .item-child{
		position:static;
		margin-bottom:20px;
		width:100%;
	}

	#dao-feature-02 .item-child:last-child{
		margin-bottom:0;
	}

	#dao-feature-02 h4{
		margin-top:0;
		font-size:32px;
	}

	#portfolio article{
		text-align:center;
		margin-bottom:20px;
	}

	#portfolio article img{
		margin:0 auto;
		display: block;
	}

	#portfolio h2{
		font-size:31px;
	}

	#bg-features .title-feature h2, .bg-launch-title h2{
		font-size:26px;
	}

	#bg-features .title-feature h6{
		font-size:15px;
		font-family: var(--font);
	}

	.box-features article{
		margin-bottom:45px;
	}

	.order-b{
		order:1;
	}

	.order-a{
		order:2;
	}

	.funding-head .group_btn button {
		margin-bottom:24px !important;
		padding-left:60px !important;
		padding-right:60px !important;
	}

	.intro-img article h2{
		font-size:27px !important;
		color:var(--white);
	}

	footer .title-home h2{
		font-size:21px !important;
	}

	#get-it-touch{
		background:url('../images/banner-home-3.svg') no-repeat left center;
		background-size:cover;
	}
	
	.bg-box-focus:after{
		display: none;
	}

	.bg-box-focus{
		padding:30px 0;
	}

	#box-focus{
		background:none;
		padding:0;
	}

	.box-bg img{
		width:100%;
	}

	.box-bg{
		padding:15px;
	}

	.box-bg h2{
		font-size:30px;
	}

	#feature-box{
		padding-top:0;
	}

	#feature-box .card{
		margin-bottom:20px;
	}

	#feature-box .child-article:nth-child(2) .card{
		margin-top:0;
	}

	.marquee-content li{
		width:35vw;
	}

	#slide-top h1{
		font-size:34px;
	}

	#slide-top h2{
		font-size:16px;
	}

	.padding0mobile{
		padding:0;
	}

	#recipeCarousel{
		display: none;
	}

	.content-width{
		padding-left:15px;
		padding-right:15px;
	}

	#introduction{
		padding:0 0 50px;
	}

	#introduction article h1{
		font-size:32px;
		margin-top:10px;
	}

	#introduction p{
		font-size:16px;
	}

	#introduction .text_a a{
		width:150px;
		font-size:16px;
	}

	.btn-a{
		padding: 8px 10px 12px;
	}

	.text_a{
		margin-top:30px;
	}

	.img-intro{
		margin-top:40px;
	}

	.img-intro .abs-2, .img-intro .abs-7, .abs-bg{
		display: none;
	}

	.rel-img .img-fluid{
		max-width: 100%;
	}

	#about-us article{
		padding-left:0;
	}

	.title-home{
		margin-top:20px;
	}

	#about-us h3{
		font-size:35px;
	}

	.focus article{
		padding:25px 15px !important;
		text-align:center;
		margin-bottom:20px;
	}

	.focus article p{
		margin-top:20px;
	}

	.bg-grid{
		background:none !important;
	}

	#features article ul li span{
		font-size:26px;
	}

	#partner img{
		margin-bottom:20px;
	}

	.bg-title{
		padding-left:20px;
		padding-right:20px;
	}

	.copyright{text-align:center;}

	.footer-bottom ul{
		float:none;
		margin-top:10px;
	}

	.carousel-control-next{
		right:0;
	}

	.carousel-control-prev{
		left:0;
	}

	.artic02 .pos-02{
		margin-top:-20px !important;
	}

	.rt-timeline{
		overflow-x:auto !important;
	}

	.rt-timebar__row{
		position: static !important;
	}

	.rt-grid, .rt-grid__cell{
		border-top: 1px solid rgba(255, 255, 255, 0.5);
	}

	.banner-vc{
		background-size:210% !important;
		height:96px !important;
	}

	.clear-filter{
		margin-bottom:20px;
	}

	.item-card .img-vcs{
		height:11vh;
	}

	.vc-action{
		margin-left:0;
	}

	.vcs-detail{
		padding:20px;
	}

	.vc-detail-overview-1, .vc-detail-overview-2{
		width:100%;
	}
	

	.vc-detail-overview-2, .vc-detail-overview-3{
		margin-top:20px;
		padding:0 20px;
	}

	.vcs-detail .title_main h2{
		font-size:26px;
	}

	.vc-detail-overview-3 .title_main .d-flex{
		display: block !important;
	}

	.vcs-detail .ant-table-wrapper{
		width:100%;
		overflow-x:scroll;
	}

	.ant-table-tbody > tr > td{
		min-width:120px;
	}

	.ant-table-empty .ant-table-tbody > tr.ant-table-placeholder{
		background:linear-gradient(#24252d 0%, rgba(36, 37, 45, 0.25) 100%);
	}

	.over-flow span{
		padding-left:0 !important;
		padding-right:5px;
		float:none !important;
	}

	.over-flow{
		display: block !important;
		white-space: normal;
		margin:10px 0 20px;
	}

	.vc-item-filter{
		padding:5px 0;
	}

	.vc-action .btn-detail, .vc-action .btn-view{
		width:145px;
		font-weight:normal !important;
		font-size:13px !important;
	}

	.search-config{
		margin-top:0 !important;
		width:100%;
	}

	.justify-content-end{
		justify-content: normal;
	}

	.remove_01{
		--bs-gutter-x:0 !important;
	}

	.only-d-mobile{
		display: block !important;
		margin-bottom:10px;
		margin-left: 20px;
	}

	.px-4{
		padding-right: 6.5rem !important;
		padding-left: 6.5rem !important;
	}

	.search-config{
		margin:15px 0 !important;
	}

	.ant-collapse > .ant-collapse-item{
		margin-bottom:12px;
	}

	.ul-nopdf .px-3{
		padding:0 !important;
	}

	.vc-detail-overview-2 .invest-item{
		display: block!important;
		float: left!important;
	}

	.profile-info{
		padding-left:0;
	}

	.clear-filter{
		width: 100%;
		margin: 0 auto 20px;
	}

	#list-build .item-box .nav-tabs .nav-link{
		padding:10px 15px !important;
	}

	#list-build .tab-content ul li{
		width:100% !important;
		float:none !important;
		margin-bottom:20px !important;
	}

	.banner-buidls{
		height:10vh;
	}

	#list-build .item-box{
		margin:0 0 20px 0 !important;
	}

	.banner-buidls div{
		font-size:26px;
	}

	.button-link-info{
		margin-top:0 !important;
		margin-bottom:20px;
	}

	#nav-tab{
		margin-top:20px !important;
	}

	.dibi{
		padding-left:0 !important;
		margin-top:30px;
	}

	.box-trans ul li{
		width:100% !important;
		float:none !important;
	}

	.box-detail-summary{
		padding:25px !important;
	}

	.box-img{
		width:100%;
	}

	.box-trans{
		margin-bottom:20px;
	}

	.ant-table-wrapper{
		overflow-x:scroll;
	}

	.investment_de{
		padding-left:0;
	}

	.investment_de ul li b{
		margin-right:5px;
	}

	.public-builds p{
		font-size:16px;
	}

	.public-builds ul li{
		padding:10px 22px;
	}

	.public-builds ul{
		margin-top:20px;
		margin-bottom:20px;
	}

	.rel-img img{
		max-width: 100%;
	}

	.box-abs-d img{
		max-width:65px;
	}

	.rel-img .img-fluid{
		margin-left:0;
	}

	.bg-img{
		background-position:-13% -30px;
		padding:60px 0;
	}

	#interested .title-home h2{
		line-height:1.1;
	}

	.menu-footer li{
		float:left;
		width:50%;
	}

	.logo-footer p{
		font-size:17px;
		line-height:1.4;
	}
	
	.logo-footer p small{
		line-height:1.8;
	}

	footer{
		padding:50px 0 !important;
	}

	.title-home hr{
		margin:13px 0;
	}

	.title-home h2:before{
		height: 4px;
		bottom: -16px;
		z-index: 3;
	}

	.banner-vc{
		background-position: left center;
	}

	.waiting-update{
		padding:90px 0;
	}

	.waiting-update img{
		max-width:100% !important;
	}

	.waiting-update span{
		font-size:23px !important;
		display: block;
	}

	#pool-home .title-home h2:before{
		bottom: -22px !important;
	}

	#about-us .title-home h2:before,
	#partner .title-home h2:before{
		bottom: -32px !important;
	}

	#interested .title-home h2:before{
		bottom:-16px !important;
	}

	.footer-bottom ul li{
		margin-left:40px;
	}

	.padding0right{
		padding-right:0;
	}

	.vcs-list .item-card{
		margin-left:0;
		margin-right:0;
	}

	.footer_bg .logo-footer{
		text-align:center;
	}

	.footer_bg .title-home h2{
		text-align:center;
	}

	.footer_bg .title-home h2:before{
		left: 43%;
	}

	.footer_bg .title-home hr{
		display: block;
		margin-left:auto;
		margin-right:auto;
	}

	.menu-footer-all li a{
		text-align:center;
	}
}