.rt-track {}

.rt-track__elements {
  position: relative;
  // height: $react-timelines-track-height + $react-timelines-border-width;
  height: 50px;
  //border-bottom: $react-timelines-border-width solid $react-timelines-keyline-color;
}

.rt-track__element {
  position: absolute;
  height: $react-timelines-track-height - 2 * $react-timelines-element-spacing;
  top: $react-timelines-element-spacing;
}
