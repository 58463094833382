.pool-detail {
  width: 100%;
  height: 100%;
  /* background-color: aliceblue; */
  position: relative;
}
.pool-detail-header-left {
  margin-bottom: 60px !important;
  position: relative;
  width: 100%;
  height: 20vh;
  background-color: rgb(52, 104, 150);
}
.pool-detail-header-right {
  position: absolute;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  bottom: -50px;
}
.pool-detail-name {
  padding-top: 50px;
  font-size: 20px;
  font-weight: 600;
}
.pool-detail-header-avatar img {
  width: 120px;
  height: 120px;
  background-repeat: no-repeat;
  border-radius: 50%;
}

.pool-detail-content {
  padding: 0 20px;
}
.pool-detail-info {
  border-radius: 5px;
  padding: 10px 20px;
  border: 1px solid #969696;
}
.project-name {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 3px;
}
.copy-address {
  cursor: pointer;
}
.video-introduce {
  position: relative;
  border-radius: 5px;
}
.pool-detail-content-tokennomic {
  padding: 0 20px;
  margin-top: 20px;
}
.title-investment {
  font-size: 20px;
  font-weight: 600;
}
.progress {
  border: 1px solid #b0b0b0;
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 0 3px #b0b0;
  backdrop-filter: blur(2px) brightness(101%);
}
.bar {
  width: 20em;
  background: forestgreen;
  box-shadow: inset 0px 12px 1.7px #ffffff33;
  border-radius: 3px;
  color: white;
  text-align: center;
}
.bar.spanish {
  background: dodgerblue;
  width: 82%;
}
.history-list {
  margin-bottom: 10px;
}
.watch-more {
  color: rgb(40, 51, 218);
  cursor: pointer;
  text-decoration: underline;
  font-size: 15px;
}
.join-in {
  right: 50px;
  position: absolute;
}
