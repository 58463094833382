.rt-grid,
.rt-grid__cell {
  position: absolute;
  top: 0;
  bottom: 0;
}

.rt-grid {
  left: 0;
  right: 0;
}

.rt-grid__cell {
  // background: #fff;
  //border-left: 1px solid $react-timelines-keyline-color;
  border-right: 1px solid rgb(117, 117, 117);
}
