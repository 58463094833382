.pool-public {
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #fff;
  width: 100%;
  height: 100%;
}

.pool-public-header {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000;
  width: 80%;
  height: 30vh;
}
.pool-public-content {
  padding: 10px 150px;
  width: 100%;
  border-top: 2px solid rgb(61, 60, 60);
}
.pool-public-content-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.select-status {
  width: 100%;
}
.pool-public-content-body::-webkit-scrollbar {
  display: none;
}
.pool-public-content-body-item {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 50vh;
  margin-bottom: 10px;
  border: 1px solid rgb(61, 60, 60);
}
.status-pool {
  position: absolute;
  color: #fff;
  right: 0;
  top: 0;
  padding: 5px 15px;
  background-color: rgb(36, 15, 224);
  border: 1px solid rgb(138, 138, 138);
}
.more-out {
  position: absolute;
  font-size: 20px;
  font-weight: 700;
  bottom: 0;
  right: 0;
  cursor: pointer;
}
.pool-item {
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}
.pool-img {
  display: flex;
  justify-content: center;
  width: 100%;
}
.pool-name {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pool-raise {
  text-align: center;
  width: 100%;
  height: 20px;
}
.pool-status {
  text-align: center;
  width: 100%;
  height: 20px;
  color: red;
}

.current-raise {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  color: rgb(22, 143, 58);
}
.pool-item .pool-img img {
  width: 80%;
}
.custom-progress {
  width: 15vw;
  margin-left: 35px;
}
